<style lang='postcss'>
.first-level-title {
  @apply mb-8;
  height: 100px;
}
@screen md {
  .first-level-title {
    height: 162px;
  }
}
</style>

<template>
  <div class="first-level-title">
    <p class="game-level">1 уровень</p>
    <h1 class="game-title-1">Придумай пароль</h1>
  </div>
</template>

<script>
export default {
  name: "FirstLevelTitle",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
