<style lang="postcss">
.landing-content {
  @apply w-full font-sb_sans_caps mx-auto text-left flex-grow;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  &__title {
    @apply -mx-2 flex flex-wrap justify-between flex-col md:flex-row relative mb-8;
    & span {
      @apply inline-block mx-2;
    }
  }
  &__subtitle {
    @apply -mx-2 flex justify-between items-center flex-wrap mt-3;
    & span {
      @apply inline-block mx-2;
    }
    & svg {
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__stroke-eyes {
    @apply md:ml-2 absolute md:relative flex items-center flex-col md:flex-row right-2 md:right-0 top-0 h-full md:h-auto justify-between;
    color: #469d5d;
    &-item {
      @apply md:mr-5 w-35px md:w-40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__eyes-item {
    @apply inline-block w-34px md:w-40px mx-2;
    color: #469d5d;
  }
}
.landing-btn {
  @apply w-full px-6 font-chava text-left block cursor-text relative;
  --height: 63px;
  --border-width: 2px;
  font-size: 32px;
  border: 2px solid #ffffff;
  height: var(--height);
  line-height: calc(var(--height) - 2 * var(--border-width));
  color: rgba(255, 255, 255, 0.7);
  margin-top: 29px;
  margin-bottom: 14px;
}

@screen md {
  .landing-content {
    width: 713px;
    font-size: 69px;
    line-height: 81px;
  }
}
.l-text-secondary {
  @apply font-sb_sans_display;
  font-size: 16px;
  line-height: 110%;
  color: #e5dfc8;
}
</style>

<template>
  <div class="landing-content" :style="{ marginTop: contentMarginTop }">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <level-complete-first v-if="firstLevelComplete" />
      <div v-else>
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >
          <div v-if="showLandingInfo" class="landing-content__title">
            <span>Думаешь,</span>
            <span>придумать</span>
            <div class="landing-content__stroke-eyes">
              <icon-eye-stroke
                v-for="i in strokeEyeNumber"
                :key="i"
                class="landing-content__stroke-eyes-item"
              />
            </div>
            <span>надёжный</span>
          </div>
          <first-level-title v-else />
        </transition>

        <game-password @addValue="showLandingInfo = false" />

        <transition leave-active-class="animate__animated animate__fadeOut">
          <div v-if="showLandingInfo" class="landing-content__subtitle">
            <span>это просто ?</span>
            <icon-eye
              v-for="i in eyeNumber"
              :key="i"
              class="landing-content__eyes-item"
            />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import IconEyeStroke from "@/components/Icons/IconEyeStroke.vue";
import IconEye from "@/components/Icons/IconEye.vue";
import GamePassword from "@/components/UI/GamePassword.vue";
import FirstLevelTitle from "../../components/FirstLevelTitle.vue";
import LevelCompleteFirst from "../../components/LevelCompleteFirst.vue";

export default {
  components: {
    IconEyeStroke,
    IconEye,
    GamePassword,
    gDigitalCounter: () => import("@geeckocom/g-digital-counter"),
    FirstLevelTitle,
    LevelCompleteFirst,
  },
  name: "LandingPage",
  data() {
    return {
      listener: null,
      showLandingInfo: true,
    };
  },
  mounted() {},
  methods: {},
  computed: {
    contentMarginTop() {
      return `${(document.documentElement.clientHeight - 100 - 352) / 2}px`;
    },
    strokeEyeNumber() {
      return document && document.documentElement.clientWidth < 768 ? 4 : 5;
    },
    eyeNumber() {
      return document && document.documentElement.clientWidth < 768
        ? Math.floor((document.documentElement.clientWidth * 2 - 234 - 32) / 50)
        : 5;
    },
    firstLevelComplete() {
      return this.$store.state.game.firstLevelComplete;
    },
  },
  watch: {
    firstLevelComplete(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },
  },
  destroyed() {},
};
</script>
